<template>
  <div class="page" >
     
      <Head type="0">
          <template #title>  
            <div v-if="data">{{data.advertisementTitle}}</div>  
          </template>
        
      </Head>
      
      <div class="box" >
          <div class="warp" v-if="data">

             <!-- <div class="item">
                  <transition name="van-fade">
                 <div class="cssbox" v-if="!finalVideo">
                    
                
                  <div class="loader">
                        <div style="--x:0"></div>
                        <div style="--x:1"></div>
                        <div style="--x:2"></div>
                        <div style="--x:3"></div>
                        <div style="--x:4"></div>
                        <div style="--x:5"></div>  		    </div>
                       
              </div>
               </transition>
            <video  :src="data.advertisementVideo" ref="video" webkit-playsinline :controls="false" width="100%" playsinline  autoplay loop muted @canplaythrough="loadSuccess()"   @click=" $refs.video.paused? $refs.video.play():$refs.video.pause()">
                
               
            </video>
           </div> -->
           <img :src="item" v-for="item in data.advertisingInfo.showInfo" :key="item">
    <div class="banana" >
        <div class="text">
            {{data.advertisementCopywriting}}
        </div>
        
        <div class="label" v-if="data.advertisingInfo.companyFlag - 0">
            <span style="display:inline-block;background:#FF5200;height:18px;width:5px"> </span>
            <div class="label-title">{{data.advertisingInfo.companyName}}</div>
        </div>
        <div class="text" v-if="data.advertisingInfo.companyFlag">
           {{data.advertisingInfo.companyInfo}}
        </div>
    </div>
    <div style=" display: flex;flex-direction: column;" v-if="data.advertisingInfo.companyFlag - 0">
     <van-image 
           v-for="item in data.advertisingInfo.companyShow" 
           :key="item"
          :src="item"
          width="100%"
          height="210px"
          ></van-image>

          </div>
          <div class="banana" v-if="data.advertisingInfo.pointFlag - 0">
        
        <div class="label">
            <span style="display:inline-block;background:#FF5200;height:18px;width:5px;"> </span>
            <div class="label-title">产品买点</div>
        </div>
        <div class="text">
            {{data.advertisingInfo.pointInfo}}
        </div>
    </div>
    <div  style=" display: flex;flex-direction: column;" v-if="data.advertisingInfo.pointFlag - 0">


       <van-image 
           v-for="item in data.advertisingInfo.pointShow" 
           :key="item"
          :src="item"
          width="100%"
          height="210px"
          ></van-image>
            </div>
            <div class="banana">
         <div class="label">
             
            <span style="display:inline-block;background:#FF5200;height:18px;width:width:5px;"> </span>
            <div class="label-title">预约咨询</div>
         </div>
         <ul>
             <li class="van-hairline--bottom">
                 <van-field v-model="userName" label="姓名" placeholder="请输入姓名" />
             </li>
             <li class="van-hairline--bottom">
                 <van-field v-model="phoneNubmer" label="手机号" placeholder="请输入手机号" />
             </li>
              <li class="van-hairline--bottom">
              
                 <van-field
                    readonly
                    clickable
                    label="地区"
                    :value="location.join('/')"
                    placeholder="请选择地区"
                    @click="showPicker = true"
                    />
             </li>
         </ul>
         
       </div>
      
      </div>
      
       </div>
       
   <div class="fotter">
       <div class="btn" @click="load">提交</div>
   </div>
    <van-popup v-model="showPicker" round position="bottom">
                <!-- <van-picker
                    show-toolbar
                    
                    @cancel="showPicker = false"
                    @confirm="confirm"
                    :options="areaList"
                /> -->
                    <van-area title="地区"  :area-list="areaList"   @confirm="confirm"  @cancel=" showPicker = false" />
                </van-popup>
  
  <!-- <div class="alert">
        <div> -->
            <!-- <svg t="1641891378541" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6219" width="64" height="64"><path d="M969.6 208c-9.6-9.6-25.6-9.6-35.2 0l-508.8 537.6c-19.2 19.2-48 19.2-70.4 3.2l-265.6-252.8c-9.6-9.6-25.6-9.6-35.2 0-9.6 9.6-9.6 25.6 0 35.2l265.6 252.8c38.4 38.4 102.4 35.2 137.6-3.2l508.8-537.6C979.2 233.6 979.2 217.6 969.6 208z" p-id="6220" fill="#1afa29"></path></svg> -->
       
        <!-- <svg  class="line"  version="1.1" xmlns="http://www.w3.org/2000/svg" style="display:flex;align-items:center;" >
        <circle cx="60.5" cy="40" r="30" class="circle__progress  circle__progress--fill circle__svg" ></circle>
        :style="{strokeDashoffset:n}"
        <path d="M250 150 L150 350 L350 350 Z" fill="#1afa29"/>
            <polyline points=" 35,35 50,50 90,20"  style="stroke:#00EC00 ;strok-width:2;fill:none" class="circle__progress  " />

            <polyline x1="35" y1="35" x2="50" y2="50" x3="85" y3="15" style="stroke:#00EC00;stroke-width:3 "  />
            <line x1="50" y1="50" x2="85" y2="15" style="stroke:#00EC00;stroke-width:3 "  />
         </svg>
        -->
        <!-- <canvas fillStyle="none" strokeStyle="#00EC00"></canvas>
        </div>
        <div class="tip">
            1111
        </div>
    </div> -->
  </div>
</template>

<script>
import Head from '../../components/head/index.vue'

import { areaList } from '@vant/area-data';
import { getAdDetail ,upForm} from '../../api/adForm/index'
export default {
    data(){ 
         return{ 
             location:[] ,
             phoneNubmer:null,
             value:null,
             userName:null,
             showPicker:false ,
             areaList,
            //  n:238,
             data:null,
             finalVideo:false    
         }
    },
    created(){ 
        try{ 
            this.$route.query.session && this.$store.commit('setSession',this.$route.query.session)
        }catch{ 
            //
        }
       
        this.getUser().then( () => { 
             console.log(this.$store.state.userData)
        })
        this.setRem()
        getAdDetail(this.$route.query.id).then(res => { 
            if(res.data.code == 0){ 
                this.data = res.data.data
            }
        })
    },
   
    methods:{ 
        // 加载成功，视频显示
        loadSuccess(){ 
            this.finalVideo = true
        },
        
        load(){ 

            if(!this.userName){ 
                this.$toast('请填写姓名')
                return 
            }
            if(!this.phoneNubmer ){ 
                this.$toast('请填写手机号')
                return
            }
            // 如果输入的地点的长度为 0 提示用户输入地区
            if(this.location.length == 0){ 
                this.$toast('请选择地区')
                return
            }
            console.log(this.phoneNubmer ,this.userName,this.location)

            // if(this.username !=null && (this.phoneNubmer!=null) && (this.location.length>0)){

                 upForm({ taskId          : this.$route.query.id ,
                    registerMobile  : this.phoneNubmer,
                    registerCode    : this.userName,
                    province        :this.location[0],
                    city            :this.location[1],
                    area            : this.location[2],  
                    }).then(res =>{ 
                        if(res.data.code == 0){ 
                            this.$toast.success('提交成功')
                        }
                    })
            // }else{ 
            //     this.$toast('请填写完整信息')
            // }
          
        },
        cancel(){ 
            this.showPicker = false
            //
        },
        confirm(val){
            this.showPicker = false
           
            this.location = val.map(item => item.name)
            console.log(this.location);
            
        },
       
    },
   
components:{Head }
}
</script>

<style lang="less" scope>

.circle__progress {
 fill:none;
  stroke-width: 3;  
//   stroke-opacity: 0.3;
  stroke-linecap: round;
  stroke: #00EC00;
  stroke-dasharray:200;
  stroke-dashoffset:200;

 
 
}
.circle__svg {
  transform: rotate(-90deg);
  transform-origin:center;
}
// .circle__progress--fill {
//   --initialStroke: 0;
//   --transitionDuration: 0;
//   stroke-opacity: 1;
//   stroke-dasharray: var(--initialStroke);
//   stroke-dashoffset: var(--initialStroke);
//   transition: stroke-dashoffset var(--transitionDuration) ease;
// }
svg{ 
    height: 80px;
    width: 100%;
}
.alert:hover{
    
    svg{ 
        transition:all 0.5 linear;
        width: 100%;
        height: 80px;
    }
    
}
.alert{ 
    background-color:rgba(255, 255, 255, 0.9);
    box-shadow: 0px 2px 2px 0px #e6e6e6;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 120px;
    width: 120px;
    border-radius: 10px;
    position: fixed;
    top: 20%;
   left:50%;
   transform: translateX(-50%);
    z-index: 200;
    backdrop-filter:blur(2px);
  svg{ 
      font-size: 32px;
  }
}
.page{ 
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    color: #333333;
    background: white;
    .box::-webkit-scrollbar{ 
        display: none;
       
        
    }
    .box{ 
        flex: 1;
        overflow: auto;

        .warp{ 
            padding-bottom: constant(safe-area-inset-bottom);
            padding-bottom: env(safe-area-inset-bottom);
        }
        video{ 
            width: 100%;
            max-height: 200px;
        }
    }

}
.banana{ 
    padding: 10px 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-size:14px;
   
}

.label{ 
    display: flex;
    padding: 10px 0 9px;
    line-height: 21px;
    .label-title{ 
        font-size: 16px;
        font-weight: 600;
    }
    span{ 
        margin-right:  10px;
    }
}
.fotter{ 
    position: relative;
    padding: 10px 16px 10px 0 ;
    box-shadow: 5px 0px 10px 0 #aaa;
    z-index: 50;
    .btn{ 
        margin-bottom: constant(safe-area-inset-bottom);
        margin-bottom: env(safe-area-inset-bottom);
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        height: 36px;
        border-radius:18px ;
        background-image: linear-gradient(to right ,#FF7200 , #FF3C00 );
    }

}
.item{ 
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    height: 100%;
       position: relative;
}
.cssbox{
   position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 100;
    background: black;
    margin-bottom: 50%;
    transform: translateY(-50%);
}
 .loader {
   
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 200px;
    transform: scale(0.3);
     div {
        position: relative;
        width: 40px;
        height: 200px;
        margin: 20px;
        overflow: hidden;
        border-radius: 50px;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.05), #edf1f4);
        border: 2px solid #edf1f4;
        box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.1),
        -15px -15px 20px #fff,
        inset -5px -5px 5px rgba(255, 255, 255, 0.5),
        inset 5px 5px 5px rgba(0, 0, 0, 0.05);
      }
      div::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        border-radius: 50px;
        box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.1),
        -15px -15px 20px #fff,
        inset -5px -5px 5px rgba(255, 255, 255, 0.5),
        inset 5px 5px 5px rgba(0, 0, 0, 0.05);
      }
      div::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        box-shadow: inset -5px -5px 5px rgba(0, 0, 0, 0.1),
            0 420px 0 400px #2196f3;
        animation: animate 2.5s ease-in-out infinite;
        animation-delay: calc(var(--x) * -0.3s);
        transform: translateY(160px);
      }
       @keyframes animate {
        0% {
            transform: translateY(160px);
            filter: hue-rotate(0deg);
        }
        50% {
            transform: translateY(0px);
            filter: hue-rotate(180deg);
        }
        100% {
            transform: translateY(160px);
            filter: hue-rotate(360deg);
        }
      }
    }


</style>


